<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>点检计划</span>
        <el-button @click="add" class="btn" type="primary" size="mini" :disabled="roleId == 4">新增计划</el-button>
      </div>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="taskCode" label="点检代号"></el-table-column>
          <el-table-column prop="departmentName" label="所属部门"></el-table-column>
          <el-table-column prop="patrolNames" label="巡视点名称" width="400">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.patrolNames" :key="index">
                {{ scope.row.patrolNames.length-1 > index ? item+'、' : item }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="patrolCount" label="巡视点数量"></el-table-column>
          <el-table-column prop="deviceCount" label="巡视点设备数量" width="120"></el-table-column>
          <el-table-column prop="startDate" label="开始日期">
            <template slot-scope="scope">
              {{ scope.row.startDate ? dayjs(scope.row.startDate).format('YYYY-MM-DD'):'-' }}
            </template>
          </el-table-column>
          <el-table-column prop="submitters" label="执行人">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.submitters" :key="index">
                {{ scope.row.submitters.length-1 > index ? item+'、' : item }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="creator" label="创建人"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="70">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0" size="small" type="success">启用</el-tag>
              <el-tag v-else size="small" type="danger">禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-dropdown v-if="staffId == scope.row.creatorId" placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><div @click="details(scope.row)">详情</div></el-dropdown-item>
                  <el-dropdown-item><div @click="copys(scope.row)">复制</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 4"><div @click="onOff(scope.row)">{{ scope.row.status == 0 ? '禁用':'启用' }}  </div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown v-else placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-dis">
                    操作<i class="el-icon-arrow-down"></i>
                  </span>
                </span>
                <el-dropdown-menu slot="dropdown"></el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
      staffId: 0,
      roleId: 0
    }
  },
  methods: {
    dayjs,
    onOff(row) {
      this.$ajax.post("ruleStatus", {
        id: row.id,
        status: row.status == 0 ? 1 : 0
      }).then((res) => {
        this.loadTableData();
      });
    },
    copys(row) {
      this.$router.push({ name: 'checkCopys', params: {row: row}})
    },
    details(row) {
      this.$router.push({ name: 'checkDetail', params: {row: row}})
    },
    edit(row) {
      this.$router.push({ name: 'checkEdit', params: {row: row}})
    },
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("ruleDelete", {
          taskRuleId: row.id,
        }).then((res) => {
          this.loadTableData();
        });
      });
    },
    add() {
      this.$router.push('/check/add')
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post('ruleQuery', {
        pageNum: this.page,
        pageSize: this.size
      },{
        taskType: 1
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() { 
    this.getLastPage()
    this.getSize()
    this.loadTableData()
    this.staffId = sessionStorage.getItem('id')
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
  }
}
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.btn {
  float: right;
}
</style>